import {
  executeCustomModuleCall,
  getModuleData,
} from '@/services/api/module.api';
import moment from 'moment';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';

const state = {
  moduleData: {},
  moduleStatus: '',
  moduleId: null,

  halls: [],

  selectedAssemblyHallConfigurations: [],
  selectedAssemblyHallLanes: [],
  selectedConfigurationSteps: [],
  selectedAssemblyHallLocations: [],
  loadingLanesAndLocations: false,

  selectedAssemblyHall: null,
  selectedAssemblyLocation: null,

  currentTact: null,
  assemblyTasks: [],
  assemblySubTasks: [],

  showTasks: true,
  showPlanning: true,

  isFetchingTact: false,
  isFetchingHallTasks: false,
  isFetchingSubTasks: false,

  language: 'en',
};
const mutations = {
  set_language(state, language) {
    state.language = language;
  },
  set_module_id(state, id) {
    state.moduleId = id;
  },
  toggle_tasks_sidebar(state) {
    state.showTasks = !state.showTasks;
  },
  toggle_planning_sidebar(state) {
    state.showPlanning = !state.showPlanning;
  },
  request(state) {
    state.moduleStatus = 'loading';
  },
  success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  set_assembly_hall(state, hall) {
    state.selectedAssemblyHallConfigurations = [];
    state.selectedAssemblyHallLanes = [];
    state.selectedAssemblyHallLocations = [];
    state.selectedAssemblyHall = hall;
    state.loadingLanesAndLocations = true;
  },
  reset_assembly_hall(state) {
    state.selectedAssemblyHall = null;
  },
  module_stations_dashboard_set_assembly_hall_configurations(
    state,
    configurations
  ) {
    state.selectedAssemblyHallConfigurations = configurations;
  },
  module_stations_dashboard_set_assembly_hall_locations(state, locations) {
    state.selectedAssemblyHallLocations = locations;
    state.loadingLanesAndLocations = false;
  },
  module_stations_dashboard_set_assembly_hall_lanes(state, lanes) {
    state.selectedAssemblyHallLanes = lanes;
  },
  module_stations_dashboard_set_assembly_configuration_steps(state, steps) {
    state.selectedConfigurationSteps = steps;
  },
  set_assembly_location(state, location) {
    state.selectedAssemblyLocation = location;
  },
  reset_assembly_location(state) {
    state.selectedAssemblyLocation = null;
  },
  set_current_tact(state, tact) {
    state.currentTact = tact;
  },
  set_module_assembly_tasks(state, tasks) {
    state.assemblyTasks = tasks;
  },
  set_location_assembly_tasks(state, tasks) {
    state.assemblySubTasks = tasks;
  },
  reset_location_assembly_tasks(state) {
    state.assemblySubTasks = [];
  },
  set_halls(state, halls) {
    state.halls = halls;
  },

  setFetchingTact(state, status) {
    state.isFetchingTact = status;
  },

  setFetchingHallTasks(state, status) {
    state.isFetchingHallTasks = status;
  },

  setFetchingSubtasks(state, status) {
    state.isFetchingSubTasks = status;
  },
};
const actions = {
  toggleTasksSidebar({ commit }) {
    commit('toggle_tasks_sidebar');
  },
  togglePlanningSidebar({ commit }) {
    commit('toggle_planning_sidebar');
  },
  async fetchStationDashboardModuleData(
    { commit },
    { projectId, moduleId, sessionId }
  ) {
    try {
      commit('set_module_id', moduleId);
      commit('request');
      const moduleData = await getModuleData(projectId, moduleId, sessionId);
      commit('success', moduleData);
      return Promise.resolve();
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
      return Promise.reject();
    }
  },
  async selectStationDashboardHall({ commit, rootGetters, state }, hall) {
    try {
      commit('set_assembly_hall', hall);

      const { configurations, locations } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
            project: rootGetters.project.id,
            as: 'locations',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_hall',
                conditions: [
                  {
                    operator: '=',
                    value: hall.id,
                    boolean: 'or',
                  },
                ],
              },
              {
                name: 'assembly_lane',
              },
              {
                name: 'assembly_location',
              },
              {
                name: 'description',
              },
              {
                name: 'hall_configuration',
              },
              {
                name: 'order',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_HALL_CONFIGURATIONS',
            project: rootGetters.project.id,
            as: 'configurations',
            columns: [
              {
                name: 'assembly_hall',
                conditions: [
                  {
                    operator: '=',
                    value: hall.id,
                    boolean: 'or',
                  },
                ],
              },
              {
                name: 'title',
              },
            ],
          },
        ],
      });

      const { lanes, steps } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LANES',
            project: rootGetters.project.id,
            as: 'lanes',
            sortBy: 'order',
            columns: [
              {
                name: 'configuration',
                conditions: [
                  {
                    operator: '=',
                    values: configurations.records.map((id) => id.id),
                  },
                ],
              },
              {
                name: 'title',
              },
              {
                name: 'color',
              },
              {
                name: 'order',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_STEPS',
            project: rootGetters.project.id,
            as: 'steps',
            sortBy: 'order',
            columns: [
              {
                name: 'configuration',
                conditions: [
                  {
                    operator: '=',
                    values: configurations.records.map((id) => id.id),
                  },
                ],
              },
              {
                name: 'title',
              },
              {
                name: 'order',
              },
            ],
          },
        ],
      });

      const tasks = await executeCustomModuleCall(
        rootGetters.project.id,
        state.moduleId,
        'fetchActiveLocationIds',
        {
          HallId: hall.id,
        }
      );

      let activeLocations = [];

      tasks.forEach((t) => {
        if (
          t.task_type.location &&
          !activeLocations.some((x) => x.location.id === t.task_type.location)
        ) {
          activeLocations.push({
            location: {
              ...locations.records.find((l) => l.id === t.task_type.location),
            },
            project: t.task_project,
          });
        }
      });

      commit(
        'module_stations_dashboard_set_assembly_hall_configurations',
        configurations.records
      );
      commit(
        'module_stations_dashboard_set_assembly_hall_lanes',
        lanes.records
      );
      commit(
        'module_stations_dashboard_set_assembly_configuration_steps',
        steps.records
      );
      commit(
        'module_stations_dashboard_set_assembly_hall_locations',
        activeLocations
      );
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },

  async selectStationDashboardLocation({ commit }, location) {
    try {
      commit('set_assembly_location', location);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },

  async fetchStationDashboardCurrentTact({ commit, rootGetters, state }) {
    if (state.isFetchingTact) {
      return; // Early return if a fetch operation is already in progress
    }
    commit('setFetchingTact', true);

    try {
      const tact = await executeCustomModuleCall(
        rootGetters.project.id,
        state.moduleId,
        'fetchCurrentTact',
        {
          HallId: state.selectedAssemblyHall.id,
        }
      );
      commit('set_current_tact', tact);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    } finally {
      commit('setFetchingTact', false); // Reset the flag after the operation
    }
  },

  async fetchStationDashboardModuleAssemblyTasks({
    commit,
    rootGetters,
    state,
  }) {
    if (state.isFetchingHallTasks) {
      return; // Early return if a fetch operation is already in progress
    }
    commit('setFetchingHallTasks', true);

    try {
      const moduleAssemblyTasks = await executeCustomModuleCall(
        rootGetters.project.id,
        state.moduleId,
        'fetchHallAssemblyTasks',
        {
          date: moment().format('Y-M-D'),
          hall: state.selectedAssemblyHall.id,
          location: state.selectedAssemblyLocation.id ?? null,
        }
      );
      commit('set_module_assembly_tasks', moduleAssemblyTasks);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    } finally {
      commit('setFetchingHallTasks', false); // Reset the flag after the operation
    }
  },

  async fetchAssemblySubTasksByLocation({
    commit,
    getters,
    rootGetters,
    state,
  }) {
    if (state.isFetchingSubTasks) {
      return; // Early return if a fetch operation is already in progress
    }
    commit('setFetchingSubtasks', true);

    try {
      if (getters.focusedAssemblyTask) {
        const locationAssemblyTasks = await executeCustomModuleCall(
          rootGetters.project.id,
          state.moduleId,
          'fetchAssemblySubTasks',
          {
            location: state.selectedAssemblyLocation.id,
            parent: getters.focusedAssemblyTask.id,
            module: getters.focusedAssemblyTask.sbscode,
          }
        );
        commit('set_location_assembly_tasks', locationAssemblyTasks);
      }
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    } finally {
      commit('setFetchingSubtasks', false); // Reset the flag after the operation
    }
  },

  async fetchHalls({ commit, rootGetters }) {
    try {
      const { halls } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_HALLS',
            project: rootGetters.project.id,
            as: 'halls',
            columns: [
              {
                name: 'hall',
                as: 'title',
              },
              {
                name: 'type',
              },
              {
                name: 'automated',
              },
            ],
          },
        ],
      });
      commit('set_halls', halls.records);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
};
const getters = {
  moduleId: (state) => state.moduleId,
  status: (state) => state.moduleStatus,
  selectedHall: (state) => state.selectedAssemblyHall,
  selectedHallLoading: (state) => state.loadingLanesAndLocations,
  selectedAssemblyHallConfigurations: (state) =>
    state.selectedAssemblyHallConfigurations,
  selectedHallLocations: (state) => state.selectedAssemblyHallLocations,
  selectedHallLanes: (state) => state.selectedAssemblyHallLanes,
  selectedConfigurationSteps: (state) => state.selectedConfigurationSteps,
  selectedLocation: (state) => state.selectedAssemblyLocation,
  selectedLane: (state, getters) => {
    return getters.selectedLocation
      ? getters.selectedHallLanes.find(
          (l) => l.id === getters.selectedLocation.assembly_lane
        )
      : null;
  },
  currentTact: (state) => state.currentTact,
  assemblyTasks: (state) => {
    if (state.selectedAssemblyHall?.automated) {
      return state.assemblyTasks;
    }
    return state.assemblyTasks.filter(
      (t) => t.task_type.custom_3 === state.selectedAssemblyLocation?.id
    );
  },
  focusedAssemblyTask: (state, getters) => {
    if (state.selectedAssemblyHall?.automated) {
      // TODO return custom 11 or the below
      let currentTask = getters.assemblyTasks.find(
        (t) => t.task_type.custom_11 === getters.selectedLocation?.id
      );
      if (currentTask) {
        return currentTask;
      }

      let moduleTactStartIndex = getters.assemblyTasks.findIndex(
        (task) => task.task_type.custom_9 === getters.currentTact.id
      );

      if (moduleTactStartIndex !== -1) {
        let stationIndex = getters.selectedHallLocations.findIndex(
          (location) => location.location.id === getters.selectedLocation?.id
        );

        if (moduleTactStartIndex >= 0 && stationIndex >= 0) {
          return getters.assemblyTasks[moduleTactStartIndex - stationIndex];
        }
      }
      return null;
    }
    return getters.assemblyTasks.filter(
      (t) => t.task_type.custom_3 && t.status === 'open'
    )[0];
  },
  assemblySubTasks: (state) => state.assemblySubTasks,
  assemblyLocations: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_LOCATIONS.records,
  locationTypes: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_LOCATION_TYPES.records,
  halls: (state) => state.halls,
  showTaskSidebar: (state) => state.showTasks,
  showPlanningSidebar: (state) => state.showPlanning,
  supportedLanguages: (state) => {
    // Initialize a Set to store unique keys
    let uniqueKeys = new Set();

    // Map through each assemblySubTask and parse the metadata for each item
    state.assemblySubTasks.forEach((m) => {
      if (m.metadata) {
        try {
          let parsedMetadata = JSON.parse(m.metadata); // Parse metadata

          if (parsedMetadata) {
            Object.keys(parsedMetadata).forEach((key) => uniqueKeys.add(key));
          }
        } catch (error) {
          console.error('Error parsing metadata:', error); // Handle parsing errors
        }
      }
    });

    // Convert the Set back to an array and return the list of unique keys
    return Array.from(uniqueKeys);
  },
  selectedLanguage: (state) => state.language,
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
