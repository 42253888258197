import { isUndefined } from 'lodash';
import { getTablesV2, queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import {
  buildingColumns,
  buildingLayerColumns,
  clientsTableName,
  metadataTableName,
} from '@/modules/daiwa-house-modular-europe/ProjectIntake/TableDefinitions';
import { Routines } from '@/modules/daiwa-house-modular-europe/ProjectIntake/Routines';
import { getColumnsV2 } from '@/services/api/v2/columns.v2.api';
import { createProjectV2 } from '@/services/api/v2/projects.v2.api';
import {
  defaultModules,
  defaultRoles,
  rolesToModulesMapping,
} from '@/modules/daiwa-house-modular-europe/ProjectIntake/conceptToProject';

/**
 * Translates a flat array to a zig-zag array
 * @param array
 * @returns {*}
 */
export function zigZag(array) {
  return (
    array?.map((item, i, array) => {
      const f = Math.floor;
      const index = i % 2 === 0 ? f(i / 2) : f(i / 2 + array.length / 2);

      return array[index];
    }) ?? []
  );
}

export function actionsProxyOf(actions) {
  return actions.reduce(
    (result, key) =>
      Object.assign(result, {
        [key]: ({ commit }, payload) => commit(key, payload),
      }),
    {}
  );
}

export function inputEntryOf(title, required, type, value) {
  return {
    title,
    id: decamel(title, '_'),
    slug: decamel(title, '-'),
    name: decamel(title, ' '),
    required: required ?? false,
    value: value ?? null,
    errors: [],
    type: type ?? 'text',
  };
}

export function decamel(value, delimiter) {
  return value.replace(
    /[A-Z]/g,
    (letter) => `${delimiter}${letter.toLowerCase()}`
  );
}

export function buildingInfoOf(building, floorId) {
  if (isUndefined(building)) {
    return undefined;
  }

  const { layout, info } = building;
  const floor = layout.find((l) => l.id === floorId);
  const units = floor
    ? floor.units
    : layout.reduce((sum, { units }) => sum + units, 0);
  const area = info
    .filter((i) => i.title === 'floorArea')
    .map((e) => e.value * units)
    .at(0);
  const buildingArea = info
    .filter((i) => i.title === 'buildingArea')
    .map((e) => Number(e.value))
    .at(0);

  return {
    ...building,
    units,
    floors: layout.length,
    area,
    buildingArea,
  };
}

export function ordinalToLetters(num) {
  let result = '';
  while (num >= 0) {
    result = String.fromCharCode(65 + (num % 26)) + result;
    num = Math.floor(num / 26) - 1;
  }
  return result;
}

export function getInitials(name) {
  return name
    ?.split(' ')
    .slice(0, 2)
    .flatMap((e) => e.trim().toUpperCase().slice(0, 1))
    .join('');
}

export function memoized(transform) {
  let previous = undefined;

  return (...args) => {
    previous = transform(...args)(previous);
    return previous;
  };
}

export const settledPromiseOf = (promise) =>
  Promise.allSettled([promise]).then(([result]) => result);

export function throws(message) {
  throw new Error(message);
}

export async function queryBuildings(context, name, project, projectId) {
  const response = await queryTablesV2({
    tables: [
      {
        name,
        project,
        as: 'buildings',
        columns: buildingColumns.map((name) =>
          name !== 'project_id'
            ? { name }
            : { name, conditions: [{ operator: '=', value: projectId }] }
        ),
      },
    ],
  });
  const tables = await response.buildings.records.map((building, index) => ({
    name: context.getters.tables.buildingLayers.name,
    project,
    as: building.id,
    columns: buildingLayerColumns.map((name) => {
      switch (name) {
        case 'project_id':
          return {
            name,
            conditions: [{ operator: '=', value: projectId }],
          };
        case 'building_id':
          return {
            name,
            conditions: [{ operator: '=', value: building.id }],
          };
        default:
          return { name };
      }
    }),
  }));
  const layersResponse = await queryTablesV2({ tables });

  response.buildings.records
    .sort((a, b) => a.ordinal - b.ordinal)
    .forEach((building) =>
      Object.assign(building, {
        layers: layersResponse[building.id].records.sort(
          (a, b) => a.layer - b.layer
        ),
      })
    );

  return response;
}

export const postpone = (delay) => (value) =>
  new Promise((resolve) => setTimeout(resolve, delay, value));

export const publishRoutineFailure = (store) => (routine) => {
  if (Routines.isFailure(routine) && routine.reason !== 'Guarded') {
    console.error(routine.reason);
    store.commit('showNotification', {
      content: routine.reason?.message ?? routine.reason,
      color: 'error',
    });
  }

  return routine;
};

export async function loadTables(project) {
  const response = await getTablesV2(project);
  const get = async (name) => {
    const table = response.find((t) => t.name === name);
    const columns = await getColumnsV2(table);

    return Object.assign(table, { columns });
  };

  return {
    metadata: await get('CFFA_DHME_PROJECT_INTAKE_DATA'),
    clients: await get('CFFA_DHME_PROJECT_INTAKE_CLIENTS'),
    buildings: await get('CFFA_DHME_PROJECT_INTAKE_BUILDINGS'),
    buildingLayers: await get('CFFA_DHME_PROJECT_INTAKE_BUILDING_LAYERS'),
    risks: await get('CFFA_DHME_PROJECT_INTAKE_RISKS'),
  };
}

export async function queryMetadata(masterProjectId, projectId, columns) {
  let mappedColumns = columns.map((c) => {
    return { name: c.name };
  });

  // set condition
  mappedColumns.find((c) => c.name === 'project_id').conditions = [
    {
      value: projectId,
      operator: '=',
    },
  ];
  let data = {
    tables: [
      {
        name: metadataTableName,
        project: masterProjectId,
        as: 'metadata',
        columns: mappedColumns,
      },
    ],
  };

  const { metadata } = await queryTablesV2(data);
  return metadata;
}

export async function queryClients(masterProjectId, projectId) {
  const { clients } = await queryTablesV2({
    tables: [
      {
        name: clientsTableName,
        project: masterProjectId,
        as: 'clients',
        columns: [
          { name: 'name' },
          { name: 'role' },
          { name: 'company' },
          {
            name: 'project_id',
            conditions: [
              {
                value: projectId,
                operator: '=',
              },
            ],
          },
        ],
      },
    ],
  });
  return clients;
}
