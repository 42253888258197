export const baseColumns = [
  { name: 'name', type: 'text', is_unique: true }, // , required: true
  { name: 'created', type: 'text' }, // , required: true
];

export const infoColumns = [
  { name: 'is_concept', type: 'boolean' },
  { name: 'project_id', type: 'text' }, // , required: true
  { name: 'location', type: 'text' },
  { name: 'firma', type: 'dropdown' }, // , required: true
  { name: 'status', type: 'text' }, // , required: true
  { name: 'consequence_class', type: 'text' },
  { name: 'quality_assurance', type: 'text' },
  { name: 'project_number', type: 'text' },
  { name: 'ukg_number', type: 'text' },
  { name: 'custom_nr_sap', type: 'text' },
  { name: 'costumer_nr_pv', type: 'text' }, // , required: true
  { name: 'contract_number', type: 'text' },
  { name: 'requirement_notification_number', type: 'text' },
  { name: 'vca_certification', type: 'dropdown' },
];

export const clientColumns = [
  { name: 'company', type: 'text' }, // , required: true
  { name: 'engineering', type: 'dropdown' }, // Engineeringsvorm
  { name: 'presentation_form', type: 'text' }, // Aanbiedingsvorm
  { name: 'presentation_kind', type: 'text' }, // Aanbiedingssoort
  { name: 'calculation_amount', type: 'float', prefixIcon: 'mdi-currency-eur' }, // Calculatie bedrag
  { name: 'contracted_handover_date', type: 'date' }, // , required: true
  { name: 'prognosed_handover_date', type: 'date' }, // , required: true
  { name: 'construction_agreement', type: 'dropdown' }, // aannemingsovereenkomst
  { name: 'tender', type: 'text' }, // aanbesteding
  { name: 'agreed_calculation_amount', type: 'float' }, // Calculatie besproken met Directie
  { name: 'minimal_margin', type: 'float', prefixIcon: 'mdi-percent' }, // Minimale Marge
  { name: 'permit_applicant', type: 'dropdown' },
  { name: 'build_permit_duration', type: 'dropdown' },
];

export const metadataTableName = 'CFFA_DHME_PROJECT_INTAKE_DATA';
export const clientsTableName = 'CFFA_DHME_PROJECT_INTAKE_CLIENTS';
export const metadataTableDefinition = {
  name: metadataTableName,
  columns: [...baseColumns, ...infoColumns, ...clientColumns],
};
const tableDefinitions = [
  metadataTableDefinition,
  {
    name: clientsTableName,
    columns: [
      { name: 'project_id', type: 'text', required: true },
      { name: 'name', type: 'text', required: true },
      { name: 'role', type: 'text', required: true },
    ],
  },
];

export const buildingColumns = [
  'construction_system',
  'has_furniture',
  'has_certified_floor',
  'upholstered',
  'exploitation_term',
  'function',
  'rent_quality_grade',
  'fire_resistance',
  'building_surface_area',
  'ordinal',
  'type',
  'name',
  'sub_function',
  'module_surface_area',
  'rent_duration',
  'exterior_stairs',
  'project_segment',
  'project_id',
  'system',
  'permit',
  'energy_label',
];

export const buildingLayerColumns = [
  'project_id',
  'building_id',
  'layer',
  'module_count',
];

export const risksColumns = [
  'project_id',
  'title',
  'description',
  'chance',
  'consequence',
  'ordinal',
];
export const infoEntryNames = [
  'name',
  'location',
  'firm',
  'status',
  'consequence_class',
  'quality_assurance_firm',
  'project_number',
  'ukg_number',
  'customer_number_sap',
  'customer_number_pv',
  'quotation_number',
  'requirement_notification_number',
  'vca_certification',
];

export const clientEntryNames = [
  'company',
  'engineering',
  'offer_type',
  'offered_module_state',
  'calculation_amount',
  'prognosed_handover_date',
  'contracted_handover_date',
  'construction_agreement',
  'tender',
  'agreed_calculation_amount',
  'minimal_margin',
  'permit_applicant',
  'build_permit_duration',
];

export const buildingEntryNames = [
  'name',
  'type',
  'has_furniture',
  'function',
  'sub_function',
  'construction_system',
  'system',
  'project_segment',
  'module_surface_area',
  'building_surface_area',
  'has_certified_floor',
  'exterior_stairs',
  'fire_resistance',
  'rent_quality_grade',
  'rent_duration',
  'energy_label',
];
